<template>
    <Layout>
        <div class="pageSection">
            <SideBar />

            <div class="pageContent pageContent--3">
                <p class="h1">Бонусы</p>
                <p class="h2 colorFiord pageSubtitle">
                    Почитать 📚
                </p>

                <div
                    class="popup-layout"
                    v-if="
                        isVisibleModalBonusesOnboarding ||
                            this.$store.getters.getIsReviewOnboardBonuses
                                .isReviewOnboardBonuses
                    "
                ></div>
                <div
                    :class="
                        `bonusesContent ${
                            (isVisibleModalBonusesOnboarding &&
                                isVisibleBonusesOnboarding) ||
                            (this.$store.getters.getIsReviewOnboardBonuses
                                .isReviewOnboardBonuses &&
                                isVisibleBonusesOnboarding)
                                ? 'bonusesOnboarding'
                                : ''
                        }`
                    "
                >
                    <b-card
                        v-if="isLoadingBonusesData"
                        class="bonusesContent__contentItem"
                    >
                        <b-skeleton-img no-aspect height="200px"></b-skeleton-img>
                    </b-card>
                    <div
                        v-else
                        class="bonusesContent__contentItem"
                        v-for="item in readItems"
                        :key="item.cardTitle"
                    >
                        <CardSimpleBonuses
                            :cardTitle="item.cardTitle"
                            :cardIcon="item.cardIcon"
                            :cardDescription="item.cardDescription"
                            :availablePoints="item.availablePoints"
                            :cardRoute="item.cardRoute"
                            :isActive="item.isActive"
                            :isVip="item.isVip"
                            :btnText="item.btnText"
                            :btnLink="item.btnLink"
                            :btnTooltipText="item.btnTooltipText"
                            :bonusId="item.bonusId"
                            :owned="item.owned"
                        />
                        <OnboardModal
                            v-if="
                                (isVisibleModalBonusesOnboarding &&
                                    isVisibleBonusesOnboarding) ||
                                    (isVisibleBonusesOnboardingPopup &&
                                        isVisibleBonusesOnboarding)
                            "
                            title='Раздел "Бонусы"'
                            description="Данный раздел будет полезен для тех, кто хочет углубить или структурировать свои знания."
                            :isNextButton="true"
                            nextButtonTitle="ЗАВЕРШИТЬ"
                            :onClose="() => showedBonusesOnboarding()"
                            :onCloseModal="() => showedBonusesOnboarding()"
                        />
                    </div>
                </div>

                <p class="h2 colorFiord pageSubtitle">
                    Посмотреть 🎥
                </p>
                <div
                    :class="
                        `bonusesContent ${
                            (isVisibleModalBonusesOnboarding &&
                                isVisibleBonusesOnboarding) ||
                            (this.$store.getters.getIsReviewOnboardBonuses
                                .isReviewOnboardBonuses &&
                                isVisibleBonusesOnboarding)
                                ? 'bonusesOnboarding'
                                : ''
                        }`
                    "
                >
                    <b-card
                        v-if="isLoadingBonusesData"
                        class="bonusesContent__contentItem"
                    >
                        <b-skeleton-img no-aspect height="200px"></b-skeleton-img>
                    </b-card>
                    <div
                        v-else
                        class="bonusesContent__contentItem"
                        v-for="item in watchItems"
                        :key="item.cardTitle"
                    >
                        <CardSimpleBonuses
                            :cardTitle="item.cardTitle"
                            :cardIcon="item.cardIcon"
                            :cardDescription="item.cardDescription"
                            :cardCost="item.cardCost"
                            :availablePoints="item.availablePoints"
                            :cardRoute="item.cardRoute"
                            :isActive="item.isActive"
                            :isVip="item.isVip"
                            :tooltipText="item.tooltipText"
                            :btnText="item.btnText"
                            :btnLink="item.btnLink"
                            :btnTooltipText="item.btnTooltipText"
                            :accessRestrictedText="item.accessRestrictedText"
                            :bonusId="item.bonusId"
                            :owned="item.owned"
                        />
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "@/views/layouts/Layout";
import SideBar from "@/views/partials/sideBar/sideBar";

import CardSimpleBonuses from "@/views/components/CardSimpleBonuses/CardSimple";
import OnboardModal from "@/views/components/OnboardModal/OnboardModal";

import axios from "axios";
export default {
    name: "bonuses",
    components: {
        Layout,
        SideBar,
        CardSimpleBonuses,
        OnboardModal,
    },

    watch: {
        updateBonuses: async function() {
            if (this.updateBonuses) {
                this.readItems = [];
                this.watchItems = [];
                await this.updateBonusesData();
            }
        },
        "$store.getters.getBonuses.bonuses": function() {
            this.bonuses = this.$store.getters.getBonuses.bonuses;
            this.renderPageData();
            this.updateBonuses = false;
            this.updateMemberStore();
            this.updateMemberConfirmationsStore();
        },
    },

    async beforeMount() {
        if (
            !(
                this.$store.getters.getMember &&
                this.$store.getters.getMember.data &&
                this.$store.getters.getMember.data.enabled &&
                this.$store.getters.getMember.data.enabled.bonuses
            )
        ) {
            await this.$router.push("/" + this.$route.params.slug);
        }

        await this.updateBonusesData();

        // this.updateMemberStore();
        // this.updateMemberConfirmationsStore();
    },

    async mounted() {
        this.renderPageData();
    },

    computed: {
        webinarsData() {
            return this.$store.getters.getWebinars;
        },

        member: function() {
            return (
                (this.$store.getters.getMember && this.$store.getters.getMember.data) ||
                {}
            );
        },

        confirmations: function() {
            return (
                (this.$store.getters.getConfirmations &&
                    this.$store.getters.getConfirmations.data) ||
                {}
            );
        },

        isVisibleModalBonusesOnboarding: function() {
            return (
                // this.isLoadedConfirmations &&
                this.confirmations &&
                this.confirmations.viewedWelcomePopup &&
                !(this.member.agreementData && this.member.agreementData.showRules) &&
                this.confirmations.viewedWelcomeVideo &&
                !this.confirmations.viewedOnBoardingBonuses &&
                this.$route.path === "/trejding_ot_a_do_ya_3_5/bonuses"
            );
        },

        isVisibleBonusesOnboardingPopup: function() {
            return this.$store.getters.getIsReviewOnboardBonuses.isReviewOnboardBonuses;
        },
    },

    created() {
        this.$root.$on("buyBonusEvent", async (bonusId) => {
            await this.buyBonus(bonusId);
        });
    },

    data() {
        return {
            title: "Бонусы",

            bonuses: {},

            readItems: [],

            watchItems: [],

            updateBonuses: false,

            isLoadedConfirmations: false,
            isVisibleBonusesOnboarding: true,

            isLoadingBonusesData: false,

            isReviewOnboardBonuses: this.$store.getters.getIsReviewOnboardBonuses
                .isReviewOnboardBonuses,
        };
    },
    methods: {
        async updateMemberStore() {
            const getMemberData = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/study"
            );
            if (getMemberData && getMemberData.data && getMemberData.data.result) {
                if (
                    getMemberData.data.code === "programs" ||
                    getMemberData.data.code === "banned" ||
                    getMemberData.data.code === "blocked"
                ) {
                    await this.$store.dispatch("clearMember");
                    await this.$router.push("/");
                } else {
                    await this.$store.dispatch("setMember", {
                        data: getMemberData.data,
                    });
                }
            } else {
                console.error(getMemberData.data.error);
                await this.$store.dispatch("clearMember");
                await this.$router.push("/");
                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "error",
                        text:
                            getMemberData.data.error === "Пользователь не найден"
                                ? "В момент заморозки личный кабинет недоступен, но после ее окончания Вы с лёгкостью сможете вернуться в кабинет и продолжить обучение"
                                : getMemberData.data.error,
                    },
                });
            }
        },

        async updateMemberConfirmationsStore() {
            const getMemberConfirmationsData = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/confirmations"
            );
            if (
                getMemberConfirmationsData &&
                getMemberConfirmationsData.data &&
                getMemberConfirmationsData.data.result
            ) {
                await this.$store.dispatch("setConfirmations", {
                    data: getMemberConfirmationsData.data.confirmations,
                });

                this.isLoadedConfirmations = true;
            } else {
                console.error(
                    (getMemberConfirmationsData.data &&
                        getMemberConfirmationsData.data.message) ||
                        "Ошибка при поиске статусов ознакомительных попапов"
                );
                await this.$store.dispatch("clearConfirmations");
            }
        },

        async showedBonusesOnboarding() {
            if (
                this.isVisibleModalBonusesOnboarding &&
                this.$store.getters.getIsReviewOnboardBonuses.isReviewOnboardBonuses
            ) {
                await this.axios.post(
                    "/programs/" +
                        this.$route.params.slug +
                        "/confirmations?confirmation=viewedOnBoardingBonuses"
                );
                this.updateMemberStore();
                this.updateMemberConfirmationsStore();
                this.isVisibleBonusesOnboarding = false;
                this.$store.dispatch("setIsReviewOnBoardBonuses", false);
                await this.$router.push("/profile");
                this.$store.dispatch("setIsReviewOnBoardProfile", true);
            } else {
                if (this.isVisibleModalBonusesOnboarding) {
                    await this.axios.post(
                        "/programs/" +
                            this.$route.params.slug +
                            "/confirmations?confirmation=viewedOnBoardingBonuses"
                    );
                    this.updateMemberStore();
                    this.updateMemberConfirmationsStore();
                    this.isVisibleBonusesOnboarding = false;
                } else {
                    this.$store.dispatch("setIsReviewOnBoardBonuses", false);
                    await this.$router.push("/profile");
                    this.$store.dispatch("setIsReviewOnBoardProfile", true);
                }
            }
        },

        renderPageData: function() {
            if (this.bonuses && this.bonuses.length > 0) {
                for (const bonus of this.bonuses) {
                    if (bonus.bonusGroup === "read") {
                        const item = {
                            owned: bonus.owned,
                            bonusId: bonus._id,
                            cardTitle: bonus.title,
                            cardDescription: bonus.description,
                        };

                        if (bonus.owned) {
                            item.btnLink = `/${this.$route.params.slug}/bonuses/read/${bonus.priority}`;
                            item.btnText = "Перейти";
                        } else {
                            item.isActive = bonus.canOwn;
                            item.availablePoints = bonus.points.toString();

                            if (bonus.isSpecial) {
                                item.isVip = bonus.isSpecial;
                                item.btnTooltipText =
                                    "<span class='cursorPointer'>Что бы приобрести этот бонус обновите свой пакет участия</span>";
                                item.btnText = "Недоступно";
                            } else {
                                item.cardRoute = "/";
                                item.cardCost = "Стоимость";
                                item.btnText = "Купить";

                                if (!bonus.canOwn) {
                                    item.btnTooltipText =
                                        "<span class='cursorPointer'>На данный момент у Вас недостаточно баллов. Нажмите <span class='textUnderline'>сюда</span>, чтобы узнать за что Вы можете еще получить баллы.</span>";
                                }
                            }
                        }

                        this.readItems.push(item);
                    } else if (bonus.bonusGroup === "view") {
                        const item = {
                            owned: bonus.owned,
                            bonusId: bonus._id,
                            cardTitle: bonus.title,
                            cardDescription: bonus.description,
                        };

                        if (bonus.owned) {
                            item.btnLink = `/${this.$route.params.slug}/bonuses/watch/${bonus.priority}`;
                            item.btnText = "Перейти";
                        } else {
                            item.isActive = bonus.canOwn;
                            item.availablePoints = bonus.points.toString();

                            if (bonus.isSpecial) {
                                item.isVip = bonus.isSpecial;
                                item.tooltipText = `Опция доступна только для студентов пакета &quot;${bonus.accessLevels[0].name}&quot;. Нажмите на иконку для подробной информации.`;
                                item.accessRestrictedText = bonus.accessLevels[0].name;
                                item.btnTooltipText =
                                    "<span class='cursorPointer'>Что бы приобрести этот бонус обновите свой пакет участия</span>";
                                item.btnText = "Недоступно";
                            } else {
                                item.cardRoute = "/";
                                item.cardCost = "Стоимость";
                                item.btnText = "Купить";

                                if (!bonus.canOwn) {
                                    item.btnTooltipText =
                                        "<span class='cursorPointer'>На данный момент у Вас недостаточно баллов. Нажмите <span class='textUnderline'>сюда</span>, чтобы узнать за что Вы можете еще получить баллы.</span>";
                                }
                            }
                        }

                        this.watchItems.push(item);
                    }
                }
            }
        },

        buyBonus: async function(bonus_id) {
            const buyBonusResult = await axios.get(
                "/programs/" + this.$route.params.slug + "/bonuses/" + bonus_id + "/buy"
            );
            if (buyBonusResult && buyBonusResult.data && buyBonusResult.data.result) {
                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "success",
                        text: "Бонус приобретен",
                    },
                });

                this.updateBonuses = true;
            } else {
                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "error",
                        text: buyBonusResult.data
                            ? buyBonusResult.data.error
                            : "Ошибка при покупке бонуса",
                    },
                });

                this.updateBonuses = true;
            }
        },

        async updateBonusesData() {
            this.isLoadingBonusesData = true;
            const getBonusesDataResult = await axios.get(
                "/programs/" + this.$route.params.slug + "/bonuses"
            );
            if (
                getBonusesDataResult &&
                getBonusesDataResult.data &&
                getBonusesDataResult.data.result
            ) {
                await this.$store.dispatch("setBonuses", getBonusesDataResult.data);
            } else {
                await this.$store.dispatch("clearBonuses");
                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "error",
                        text: "Ошибка при загрузке бонусов",
                    },
                });
            }
            this.isLoadingBonusesData = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "bonuses.scss";
</style>
