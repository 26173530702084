<template>
    <div class="cardSimple" v-if="cardTitle">
        <div class="cardSimple__content">
            <div class="cardSimple__info">
                <div class="cardSimple__header">
                    <p class="h3 cardSimple__title">
                        {{ cardTitle }}
                    </p>
                    <VipUpsellButton
                        v-if="isVip"
                        :text="tooltipText"
                        :accessRestrictedText="accessRestrictedText"
                    />
                </div>
                <p class="text2 colorGray cardSimple__description" v-if="cardDescription">
                    {{ cardDescription }}
                </p>
            </div>
            <div class="cardSimple__footer">
                <div class="cardSimple__button">
                    <TooltipButton
                        v-if="btnTooltipText"
                        :tooltipButtonText="btnText || 'Перейти'"
                        :text="btnTooltipText"
                    />
                    <Button
                        v-else
                        :isLink="!!btnLink"
                        :link="btnLink || ''"
                        :isDisabled="!isActive"
                        :title="btnText || 'Перейти'"
                        :onClick="bonusClick"
                    />
                </div>
                <div
                    :class="
                        `cardSimple__availablePoints ${
                            isActive ? 'colorGreen' : 'colorFroly'
                        }`
                    "
                >
                    <span class="h4" v-if="cardCost">
                        {{ cardCost }}
                    </span>
                    <p class="h1" v-if="availablePoints">
                        <span>
                            {{ availablePoints }}
                        </span>
                        <span class="h4">баллов</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/views/components/Button/Button";
import VipUpsellButton from "@/views/components/VipUpsellButton/VipUpsellButton";
import TooltipButton from "@/views/components/TooltipButton/TooltipButton";
import Alert from "@/views/partials/Alert/Alert";

export default {
    name: "cardSimple",

    components: {
        Button,
        VipUpsellButton,
        TooltipButton,
        Alert,
    },

    data() {
        return {};
    },

    props: {
        cardTitle: String,
        cardDescription: String,
        cardCost: String,
        availablePoints: String,
        isActive: Boolean,
        isVip: Boolean,
        tooltipText: String,
        accessRestrictedText: String,
        owned: Boolean,
        btnText: String,
        btnLink: String,
        btnTooltipText: String,
        bonusId: String,
    },

    methods: {
        bonusClick() {
            if (!this.owned) {
                this.$root.$emit("buyBonusEvent", this.bonusId);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "./CardSimple.scss";
</style>
