<template>
    <div class="popup-layout popup-layout--block">
        <div class="popup popup--3">
            <div class="earningPointsPopup">
                <button type="button" class="popup__close" @click="onClose"></button>
                <p class="h2 earningPointsPopup__title">
                    Как заработать баллы
                </p>
                <div class="popup__scrollContent customScroll">
                    <table class="text2 earningPointsPopup__table">
                        <tr class="colorFiord">
                            <th class="alignLeft">
                                Что нужно сделать
                            </th>
                            <th>
                                Баллы
                            </th>
                        </tr>
                        <tr
                            v-for="(item, index) in earningPointsData"
                            :key="index"
                            :item="item"
                        >
                            <td class="alignLeft">
                                {{ item.name }}
                            </td>
                            <td>
                                <span class="boxText2 boxText2--sm boxText2--lightOrange"
                                    >+{{ item.points }}</span
                                >
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="earningPointsPopup__button">
                    <Button
                        title="закрыть"
                        extraClass="button"
                        :onClick="() => onClose()"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/views/components/Button/Button";
export default {
    name: "earningPointsPopup",
    components: { Button },
    props: ["onClose"],

    computed: {
        earningPointsData() {
            return this.$store.getters.getEarningPoints.earningPoints.options;
        },
    },

    data() {
        return {};
    },

    methods: {},
};
</script>

<style lang="scss" scoped>
@import "earningPointsPopup.scss";
</style>
