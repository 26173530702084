var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('div',{staticClass:"pageSection"},[_c('SideBar'),_c('div',{staticClass:"pageContent pageContent--3"},[_c('p',{staticClass:"h1"},[_vm._v("Бонусы")]),_c('p',{staticClass:"h2 colorFiord pageSubtitle"},[_vm._v(" Почитать 📚 ")]),(
                    _vm.isVisibleModalBonusesOnboarding ||
                        this.$store.getters.getIsReviewOnboardBonuses
                            .isReviewOnboardBonuses
                )?_c('div',{staticClass:"popup-layout"}):_vm._e(),_c('div',{class:("bonusesContent " + ((_vm.isVisibleModalBonusesOnboarding &&
                            _vm.isVisibleBonusesOnboarding) ||
                        (this.$store.getters.getIsReviewOnboardBonuses
                            .isReviewOnboardBonuses &&
                            _vm.isVisibleBonusesOnboarding)
                            ? 'bonusesOnboarding'
                            : ''))},[(_vm.isLoadingBonusesData)?_c('b-card',{staticClass:"bonusesContent__contentItem"},[_c('b-skeleton-img',{attrs:{"no-aspect":"","height":"200px"}})],1):_vm._l((_vm.readItems),function(item){return _c('div',{key:item.cardTitle,staticClass:"bonusesContent__contentItem"},[_c('CardSimpleBonuses',{attrs:{"cardTitle":item.cardTitle,"cardIcon":item.cardIcon,"cardDescription":item.cardDescription,"availablePoints":item.availablePoints,"cardRoute":item.cardRoute,"isActive":item.isActive,"isVip":item.isVip,"btnText":item.btnText,"btnLink":item.btnLink,"btnTooltipText":item.btnTooltipText,"bonusId":item.bonusId,"owned":item.owned}}),(
                            (_vm.isVisibleModalBonusesOnboarding &&
                                _vm.isVisibleBonusesOnboarding) ||
                                (_vm.isVisibleBonusesOnboardingPopup &&
                                    _vm.isVisibleBonusesOnboarding)
                        )?_c('OnboardModal',{attrs:{"title":"Раздел \"Бонусы\"","description":"Данный раздел будет полезен для тех, кто хочет углубить или структурировать свои знания.","isNextButton":true,"nextButtonTitle":"ЗАВЕРШИТЬ","onClose":function () { return _vm.showedBonusesOnboarding(); },"onCloseModal":function () { return _vm.showedBonusesOnboarding(); }}}):_vm._e()],1)})],2),_c('p',{staticClass:"h2 colorFiord pageSubtitle"},[_vm._v(" Посмотреть 🎥 ")]),_c('div',{class:("bonusesContent " + ((_vm.isVisibleModalBonusesOnboarding &&
                            _vm.isVisibleBonusesOnboarding) ||
                        (this.$store.getters.getIsReviewOnboardBonuses
                            .isReviewOnboardBonuses &&
                            _vm.isVisibleBonusesOnboarding)
                            ? 'bonusesOnboarding'
                            : ''))},[(_vm.isLoadingBonusesData)?_c('b-card',{staticClass:"bonusesContent__contentItem"},[_c('b-skeleton-img',{attrs:{"no-aspect":"","height":"200px"}})],1):_vm._l((_vm.watchItems),function(item){return _c('div',{key:item.cardTitle,staticClass:"bonusesContent__contentItem"},[_c('CardSimpleBonuses',{attrs:{"cardTitle":item.cardTitle,"cardIcon":item.cardIcon,"cardDescription":item.cardDescription,"cardCost":item.cardCost,"availablePoints":item.availablePoints,"cardRoute":item.cardRoute,"isActive":item.isActive,"isVip":item.isVip,"tooltipText":item.tooltipText,"btnText":item.btnText,"btnLink":item.btnLink,"btnTooltipText":item.btnTooltipText,"accessRestrictedText":item.accessRestrictedText,"bonusId":item.bonusId,"owned":item.owned}})],1)})],2)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }