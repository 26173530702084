<template>
    <div class="tooltipButton">
        <div @click="onOpenModalEarningPoints()">
            <Tooltip
                tooltipButton
                :tooltipButtonText="tooltipButtonText"
                :text="text"
                :tooltipDirection="tooltipDirection"
            />
        </div>
        <EarningPointsPopup
            v-if="isVisibleModalEarningPoints"
            :onClose="() => onCloseModalEarningPoints()"
        />
    </div>
</template>

<script>
import Tooltip from "@/views/components/Tooltip/Tooltip";

import EarningPointsPopup from "@/views/pages/dashboard/dashboardShop/earningPointsPopup/earningPointsPopup";

export default {
    name: "TooltipButton",

    components: {
        Tooltip,
        EarningPointsPopup,
    },

    data() {
        return {
            isVisibleModalEarningPoints: false,
        };
    },

    props: {
        text: String,
        tooltipDirection: String,
        tooltipButtonText: String,
    },

    methods: {
        onOpenModalEarningPoints() {
            this.isVisibleModalEarningPoints = true;
        },
        onCloseModalEarningPoints() {
            this.isVisibleModalEarningPoints = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "tooltipButton.scss";
</style>
